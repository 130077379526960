    import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import Intro from '../../components/Intro/Intro'
import List from '../../components/List/List'
import Goals from '../../components/Goals/Goals'
import BottomNav from '../../components/BottomNav/BottomNav'
import ClientPerks from '../../components/ClientPerks/ClientPerks'
import HubSpotForm from '../../components/HubSpotForm/HubSpotForm'
import Outcomes from '../../components/Outcomes/Outcomes'
import ServiceDescription from '../../components/ServiceDescription/ServiceDescription'
import LoomVideo from '../../components/LoomVideo/LoomVideo';

const Ingram = () => {
  const howItWorks = [
    {
      id: 1,
      text: 'Connect Your Ingram Account: Link your Ingram inventory to your Shopify store in minutes.',
    },
    {
      id: 2,
      text: 'Add inventory: Search the full Ingram inventory and add any titles to your shopify storefront within minutes.',
    },
    {
      id: 3,
      text: 'Watch your orders fulfill: Track orders, monitor inventory, and enjoy hands-free operations.',
    },
  ];

  const goals = [
    {
      id: 11,
      text: 'Save Hours Every Week: No more manual updates or fulfillment hassles.',
    },
    {
      id: 22,
      text: 'Sell More Books: Attract customers with accurate listings and fast shipping.',
    },
    {
      id: 33,
      text: 'Scale Without Stress: Handle more orders and inventory effortlessly.',
    },
  ]

  const outcomes = [
    {
      id: 111,
      title: '$1500 one-time',
      subtitle: 'Shopify Ingram App Setup Fee',
    },
    {
      id: 222,
      title: '$150 / month',
      subtitle: 'Metadata Access and Hosting Fee',
    },
  ]

  const faq = [
    {
      id: 1,
      heading: 'What is the main benefit of using this Shopify app with Ingram Web Services?',
      paragraph1: 'Our app allows you to seamlessly integrate Ingram’s vast book database directly into your Shopify store. You can easily browse, filter, and bulk upload books, complete with descriptions, images, and stock information.',
      paragraph2: 'Plus, it automates the fulfillment process, saving you time and effort.',
      smallHeading: true,
    },
    {
        id: 2,
        heading: 'How does the automated fulfillment process work?',
        paragraph1: 'Once a customer places an order, the app sends a fulfillment request to Ingram’s warehouse. It waits for a confirmation and tracking number, which is then automatically sent to your customer',
        paragraph2: 'All this happens without any manual intervention, ensuring a smooth and efficient process.',
        smallHeading: true,
    },
    {
        id: 3,
        heading: 'Are there any additional costs for using Ingram Web Services through the app?',
        paragraph1: 'No additional costs are involved when using our app. The integration fee typically charged by Ingram is waived',
        paragraph2: 'You only pay a one-off implementation fee of $1500 and a monthly access fee of $150.',
        smallHeading: true,
    },
    {
        id: 4,
        heading: 'Can I apply discounts and sales labels to the books I import?',
        paragraph1: 'Absolutely. During the import process, you can easily apply discounts and sales labels to your books, providing flexibility in your pricing strategies.',
        smallHeading: true,
    },
    {
        id: 5,
        heading: 'Is the app certified by Ingram?',
        paragraph1: 'Yes, our app is certified and approved by Ingram',
        paragraph2: 'This ensures that you are using a trusted and reliable solution for your store’s integration needs.',
        smallHeading: true,
    },
    {
        id: 6,
        heading: 'How quickly can I populate my store with books using the app?',
        paragraph1: 'With our user-friendly interface and advanced filters, you can populate your store with books from the Ingram database within seconds.',
        paragraph2: 'This allows for a quick and efficient setup process',
        smallHeading: true,
    },
    {
        id: 7,
        heading: 'What kind of support is available if I encounter issues with the app?',
        paragraph1: 'We offer comprehensive support to help you with any issues you may encounter.',
        paragraph2: 'Our team is dedicated to ensuring that your experience with the app is smooth and hassle-free.',
        smallHeading: true,
    },
    {
        id: 8,
        heading: 'Can I manage the shipping process through the app?',
        paragraph1: 'Yes, the app automatically splits shipping orders into smaller chunks, calculates the shipping costs, and sends automated updates to your customers',
        paragraph2: 'This streamlines the entire shipping process for you',
        smallHeading: true,
    },
  ]

  return (
    <Layout>
      <SEO
        title="Ingram Shopify App "
        keywords={[
          `Emersoft`,
          `application`,
          `react`,
          `ai`,
          `gpt`,
          `openai`
        ]}
        description="Discover seamless integration with Ingram Web Services using our Shopify app. Effortlessly manage inventory, automate order fulfillment, and access over 7.5 million titles. Enhance your bookstore’s efficiency with real-time stock updates and easy catalog browsing. Perfect for booksellers and publishers looking to streamline their operations and boost sales"
      />
      <SectionHeader>
        Automate Fulfillment and Access Ingram's Inventory with Ease
      </SectionHeader>
      <Intro
        paragraph1="The Ultimate Shopify Integration for Independent Bookstores"
        paragraph2="Simplify operations, save time, and focus on growing your business with seamless automation."
      />
      <LoomVideo videoId="6bc66733a1604c56a4590457b9032969" />
      <ClientPerks
        heading="What you get"
        perkHeading1="Access Ingram’s Full Inventory Instantly"
        perkParagraph1="Automatically sync book details, images, pricing, and stock levels from Ingram to your Shopify store."
        perkHeading2="Automate Order Fulfillment"
        perkParagraph2="Orders placed on your Shopify store are instantly sent to Ingram for shipping—no manual work required."
        perkHeading3="Keep Your Store Up-to-Date"
        perkParagraph3="Real-time updates mean your catalog always reflects what’s available, making stockouts and errors a thing of the past."
        perkHeading4="Customize Pricing and Inventory Rules"
        perkParagraph4="Easily set pricing margins and choose which books to display, giving you full control over your catalog and profitability."
      />
      <Outcomes heading="Pricing" outcomes={outcomes} />
      <HubSpotForm 
        formId="93f2ee63-651c-439f-bdff-9c02aafe3e74" 
        portalId="46844019" 
        heading="Start Automating Today"
        paragraph="Get started with Ingram's Shopify integration today and see the difference it makes for your business."
        />
      <Goals title={'Why Our App'} goalsList={goals} />
      <Intro paragraph1="How it works" />
      <List data={howItWorks}  />{' '}
      <ServiceDescription boxes={faq} />
      <BottomNav
        additionalClass="bottom-nav--border-top"
        heading="See More"
        firstLink="/our-work"
        firstHeading="Projects"
        secondLink="/about"
        secondHeading="About Us"
      />
    </Layout>
  )
}

export default Ingram;